import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DepartmentCode,
  SystemConfig,
  SystemConfigBlobGuestService,
  SystemConfigGuestService,
  WebPortal,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs';
import { MainRoutes } from '../../../guards/guard.models';
import { OfficeDepartmentsService } from '../../../services/office-departments/office-departments.service';
import { SystemCountyBackgroundService } from '../../../services/system-county-background/system-county-background.service';
import { WebPortalService } from '../../../services/web-portal/web-portal.service';
import { RedirectionAfterLogoutQueryParamKey } from './departments.model';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss'],
})
export class DepartmentsComponent implements OnInit, OnDestroy {
  private isAlive = true;
  webPortalData: WebPortal[];
  departmentCode = DepartmentCode;
  webDepartmentsHeaderText: SafeHtml;
  mainWebPortalData: WebPortal;
  backgroundUrlFromDb: string;
  webFooterLogoExists = false;
  mainRoutes = MainRoutes;

  constructor(
    public webPortalService: WebPortalService,
    public officeDepartmentsService: OfficeDepartmentsService,
    public systemConfigGuestService: SystemConfigGuestService,
    private systemCountyBackgroundService: SystemCountyBackgroundService,
    private sanitizer: DomSanitizer,
    public systemConfigBlobGuestService: SystemConfigBlobGuestService,
    public route: ActivatedRoute,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.fetchWebPortalData();
    this.fetchMainPortalData();
    this.subscribeToWebHeaderText();
    this.maybeSetBackgroundImageFromDb();
    this.subscribeToWebFooterLogo();
    this.showLogoutInfoIfNeeded();
  }

  fetchWebPortalData(): void {
    this.webPortalService
      .getWebPortalData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => (this.webPortalData = data));
  }

  fetchMainPortalData(): void {
    this.webPortalService
      .getMainWebPortalData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (mainWebPortalData) => (this.mainWebPortalData = mainWebPortalData),
      );
  }

  subscribeToWebHeaderText(): void {
    this.systemConfigGuestService
      .getSysConfigGuestValueByKey(SystemConfig.WebEwidDepartmentsHeaderTextPl)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((webDepartmentsHeaderText) => {
        this.webDepartmentsHeaderText = this.sanitizer.bypassSecurityTrustHtml(
          webDepartmentsHeaderText,
        );
      });
  }

  maybeSetBackgroundImageFromDb(): void {
    this.systemCountyBackgroundService
      .getApplicationBase64BackgroundFromDb()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((backgroundBase64) => {
        if (backgroundBase64) {
          this.backgroundUrlFromDb = `url(data:image/png;base64,${backgroundBase64})`;
        } else {
          this.backgroundUrlFromDb = '';
        }
      });
  }

  subscribeToWebFooterLogo(): void {
    this.systemConfigBlobGuestService
      .getSysConfigWebFooterLogoData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((webFooterLogoBase64Data) => {
        if (webFooterLogoBase64Data) {
          this.webFooterLogoExists = true;
        }
      });
  }

  showLogoutInfoIfNeeded(): void {
    this.route.queryParams.subscribe((params) => {
      if (params[RedirectionAfterLogoutQueryParamKey]) {
        setTimeout(() => this.showLogoutToastr());
        this.router.navigate([], {
          queryParams: {
            [RedirectionAfterLogoutQueryParamKey]: null,
          },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  showLogoutToastr(): void {
    this.translateService
      .get('SESSION_TIMEOUT_LOGOUT')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((notificationString) => {
        this.toastr.warning(notificationString, undefined, {
          disableTimeOut: true,
          closeButton: true,
        });
      });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
